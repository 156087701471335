#globalNav {
    /* overflow: auto; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 8vh;
}

@media only screen and (max-width: 1000px){
    #globalNav {
        height: initial;
    }
    #menuIcon {
        display: inline-block;
        float: right;
        margin: 2vh 1vw;
    }
    .bar {
        width: 3vw;
        height: .3vh;
        background-color: #bbb;
        margin: .4vh 0;
    }
}

#logo {
    margin-left: auto;
    color: #bbb;
    padding: 1vw 4vw 1vw 4vw;
}
/* #rightMostItem {
    margin-right: auto;
} */

.ui.menu .item a {
    color: black;
}

.menuItem {
    width: 18vw;
}

.menuItem p {
    margin-left: auto;
    margin-right: auto;
}

#footer {
    margin-top: 10vh;
    margin-bottom: 5vh;
    background: #F9F9F9;
    /* height: 30vh; */
    width: 100%;
}

.footerDiv {
    height: 20vh;
    left: 14px;
    bottom: 0;
    display: inline-block;
    position: relative;
    margin-top: 5vh;
}

.footerText {
    color: #4183c4;
    position: absolute;
}

.footerLink {
    margin-left: 2vw;
    margin-right: 2vw;
}