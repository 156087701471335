.subtext {
    font-size: 11px;
    color: #aaa;
    margin: -10px 0 5px 0;
    margin-block-start: 0em;
    /* margin-block-end: 0em; */
    margin-inline-start: 15px;
}

.titlesubtext {
    font-size: 16px;
    color: #aaa;
    margin: -10px 0 5px 0;
    margin-block-start: 0em;
    /* margin-block-end: 0em; */
    margin-inline-start: 15px;
}